@use '@angular/material' as mat;

/* You can add global styles to this file, and also import other style files */


:root {

  --main-color: #133060;
  --alternate-color: #3CAC5E;

}

.blank-container {

  background-image: url(/assets/images/background/login-register.jpg);

}


.navbar-brand {

  img {

    height: 40px;

  }

}

// http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5

@import '@angular/material/theming';

$primary-palette: (
  50 : #e3e6ec,
  100 : #b8c1cf,
  200 : #8998b0,
  300 : #5a6e90,
  400 : #364f78,
  500 : #133060,
  600 : #112b58,
  700 : #0e244e,
  800 : #0b1e44,
  900 : #061333,
  A100 : #6c8dff,
  A200 : #3966ff,
  A400 : #063eff,
  A700 : #0035ec,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$secondary-palette: (
  50 : #e8f5ec,
  100 : #c5e6cf,
  200 : #9ed6af,
  300 : #77c58e,
  400 : #59b876,
  500 : #3cac5e,
  600 : #36a556,
  700 : #2e9b4c,
  800 : #279242,
  900 : #1a8231,
  A100 : #bbffc8,
  A200 : #88ff9f,
  A400 : #55ff76,
  A700 : #3bff62,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$primary: mat.define-palette($primary-palette, 600);
$accent: mat.define-palette($secondary-palette, 500);
$warn: mat.define-palette(mat.$red-palette, 500);
$theme: mat.define-light-theme($primary, $accent, $warn);

@include mat.all-legacy-component-themes($theme);
